import React, {
  createContext,
  useContext,
  useReducer,
  useMemo,
  useEffect,
} from 'react'
import ShopReducers from './reducers'
import { useLocalStorage } from 'hooks/useLocalStorage'

import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import theme from '@rebass/preset'

const stripePromise = loadStripe('pk_test_8g6no6pMrGzTrcV05WZprXs300t7BsQwxf')

const INITAL_STATE = { cart: [] }
const ShopContext = createContext(INITAL_STATE)

export const useShopContext = () => useContext(ShopContext)

const ShopContextProvider = ({ children }) => {
  const [localStorage, setLocalStorage] = useLocalStorage('store', INITAL_STATE)
  const [state, dispatch] = useReducer(ShopReducers, localStorage)

  const contextValue = useMemo(() => {
    return { state, dispatch }
  }, [state, dispatch])

  useEffect(() => {
    const { state } = contextValue
    setLocalStorage(state)
  }, [contextValue, setLocalStorage])

  return (
    <ShopContext.Provider value={contextValue}>{children}</ShopContext.Provider>
  )
}

export default ({ element }) => (
  <StyledThemeProvider theme={theme}>
    <ShopContextProvider>
      <Elements stripe={stripePromise}>{element}</Elements>
    </ShopContextProvider>
  </StyledThemeProvider>
)
