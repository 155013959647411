import * as actionTypes from './actionTypes'

const reducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.ADD_PRODUCT_TO_CART:
      const foundAdd = state.cart.find(
        item => item.name === action.product.name
      )

      if (foundAdd) {
        foundAdd.quantity++
        return { ...state, cart: state.cart }
      }

      return {
        ...state,
        cart: [
          ...state.cart,
          {
            name: action.product.name,
            amount: action.product.amount,
            currency: action.product.currency,
            quantity: 1,
          },
        ],
      }
    case actionTypes.REMOVE_PRODUCT_FROM_CART:
      const foundRemove = state.cart.find(item => item.sku === action.sku)

      if (foundRemove) {
        foundRemove.quantity--
      }

      return {
        ...state,
        cart: state.cart.filter(item => item.quantity > 0),
      }
    case actionTypes.CLEAR_CART:
      return { ...state, cart: [] }
    default:
      return state
  }
}

export default reducer
